@import 'variables.scss';

@mixin position() {
    .position {
      height: 35px;
      text-align: left;
      color: #ccc;
    
      .rotate {
        display: inline-block;
        transform: rotate(-90deg);
        overflow: hidden;
        text-align: left;
        width: 30px;
        height: 30px;
      }
    }
}

@mixin base() {
    display: inline-block;
    text-align: center;
    position: relative;
    width: $base-width;
  
    > span {
      display: inline-block;
      width: $base-width;
    }
  
    &:first-child {
      border-left: none;
    }
}