@import 'variables.scss';
@import 'mixins.scss';

.btn-link {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-30 {
  opacity: 0.3;
}

ul.list-unstyled {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

.popover {
  min-width: 200px;
}

.vertical-separador {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 19px;
  border: none;
  background-color: transparent;
  border-left: solid 1px #f1f1f1;
  z-index: 9;

  &:hover {
      background-color: #cccccc70 !important;
      border-left: solid 1px #f1f1f1;
  }
}

.viewer-grid {
  height: auto;
  min-height: 10vh;
  position: relative;
  overflow: scroll;
}

.sequence {
  @include position();

  display: inline-block;
  margin-left: 0;

  .base {
    @include base();
  }
}

.primerset {
  margin-top: 20px;
  height: $base-height;

  .primer {
    @include position();

    display: inline-block;
    position: absolute;
    background-color: #cccccc20;
    margin-left: 0;
    z-index: 99;

    .header {
      text-align: center;
    }

    .base {
      @include base();
    }
  }
}


